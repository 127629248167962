import React from "react"
import styled from "styled-components"
import Grid from "../zzz/layout/grid"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import jsonData from "../data/structure.json"
import LinkWrapper from "../utils/linkWrapper"
import ArrowRight from "../zzz/atoms/icons/files/arrow-right"

const Sitemap = ({ location }) => {
  const query = useStaticQuery(QUERY)
  const page = query.page
  const seo = page.seo
  const htmlSitemapData = jsonData?.htmlSitemapData
  // console.log(htmlSitemapData)
  // console.log(page)

  return (
    <Layout location={location}>
      <SEO
        title={page.title}
        location={location}
        post_id={page.pageId}
        seo={seo}
      />
      {/*<Wrapper className="introWrapper">*/}
      {/*  <Grid>*/}
      {/*    <h1 dangerouslySetInnerHTML={{ __html: page.title }} />*/}
      {/*    <PageContent dangerouslySetInnerHTML={{ __html: page.content }} />*/}
      {/*  </Grid>*/}
      {/*</Wrapper>*/}
      <Wrapper className="introWrapper">
        <Grid cols={1} gutter="15px" align="flex-center">
          {htmlSitemapData &&
            htmlSitemapData.length > 0 &&
            htmlSitemapData.map((mainItem, mainKey) => {
              return (
                <MainItemContainer className={"mainItem_" + mainKey}>
                  {mainItem.url !== "" ? (
                    <div className="mainItemLinkContainer">
                      <LinkWrapper
                        className={"mainItemLink mainItemLink_" + mainKey}
                        key={"mainItemLink_" + mainKey}
                        to={mainItem.url}
                        target="_blank"
                      >
                        {mainItem.title} <ArrowRight fill="red" size="md" />
                      </LinkWrapper>
                    </div>
                  ) : (
                    <div className="mainItemLinkContainer">
                      <div
                        className={"mainItemLink mainItemLink_" + mainKey}
                        key={"mainItemLink_" + mainKey}
                      >
                        {mainItem.title}
                      </div>
                    </div>
                  )}
                  {mainItem.children && mainItem.children.length > 0 && (
                    <ChildItemContainer>
                      <Grid cols={3} gutter="15px" align="">
                        {mainItem.children.map((childItem, childKey) => {
                          return (
                            <ChildItemInnerContainer>
                              {childItem.url !== "" ? (
                                <div className="childItemLinkContainer">
                                  <LinkWrapper
                                    className={
                                      "childItemLink childItemLink_" +
                                      mainKey +
                                      "_" +
                                      childKey
                                    }
                                    key={
                                      "childItemLink_" +
                                      mainKey +
                                      "_" +
                                      childKey
                                    }
                                    to={childItem.url}
                                    target="_blank"
                                  >
                                    {childItem.title}{" "}
                                    <ArrowRight fill="red" size="md" />
                                  </LinkWrapper>
                                </div>
                              ) : (
                                <div className="childItemLinkContainer">
                                  <div
                                    key={
                                      "childItemLink_" +
                                      mainKey +
                                      "_" +
                                      childKey
                                    }
                                    className={
                                      "childItemLink childItemLink_" +
                                      mainKey +
                                      "_" +
                                      childKey
                                    }
                                  >
                                    {childItem.title}
                                  </div>
                                </div>
                              )}
                              {childItem?.children &&
                                childItem?.children.length > 0 && (
                                  <GrandChildItemContainer>
                                    {childItem.children.map(
                                      (grandchildItem, grandchildKey) => {
                                        return (
                                          <GrandChildItemInnerContainer>
                                            <LinkWrapper
                                              className={
                                                "grandchildItemLink grandchildItemLink_" +
                                                mainKey +
                                                "_" +
                                                childKey +
                                                "_" +
                                                grandchildKey
                                              }
                                              key={
                                                mainKey +
                                                "_" +
                                                childKey +
                                                "_" +
                                                grandchildKey
                                              }
                                              to={grandchildItem.url}
                                              target="_blank"
                                            >
                                              {grandchildItem.title}{" "}
                                              <ArrowRight
                                                fill="red"
                                                size="md"
                                              />
                                            </LinkWrapper>
                                          </GrandChildItemInnerContainer>
                                        )
                                      }
                                    )}
                                  </GrandChildItemContainer>
                                )}
                            </ChildItemInnerContainer>
                          )
                        })}
                      </Grid>
                    </ChildItemContainer>
                  )}
                </MainItemContainer>
              )
            })}
        </Grid>
      </Wrapper>
    </Layout>
  )
}

export default Sitemap

const Wrapper = styled.div`
  margin-bottom: 20px;
  .grid {
    &.childGrid {
      padding: 0;
    }
  }
  &.introWrapper {
    margin-top: 20px;
  }
`
const PageContent = styled.div``
const MainItemContainer = styled.div`
  width: 100%;
  .mainItemLinkContainer {
    margin-bottom: 10px;
  }
  .mainItemLink {
    font-size: 31px;
    border-bottom: 3px solid red;
    color: #223247;
    font-weight: 300;
    line-height: 1.5;
    font-style: normal;
  }
  .mainItemLink svg {
    margin-bottom: 3px;
  }
`
const ChildItemContainer = styled.div`
  width: 100%;
  > .grid {
    padding: 0;
  }
`
const ChildItemInnerContainer = styled.div`
  .childItemLink {
    font-size: 20px;
    color: #223247;
    margin-bottom: 5px;
    display: inline-block;
  }
`
const GrandChildItemContainer = styled.div``
const GrandChildItemInnerContainer = styled.div`
  .grandchildItemLink {
    color: #223247;
    font-weight: 300;
  }
`

const QUERY = graphql`
  {
    page: localWpGraphQlPages(slug: { eq: "sitemap" }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      content
      pageId
    }
  }
`
